import Head from "next/head";
import Link from "next/link";
import { Button, Result } from "antd";

const title = "Страница не найдена";

export default function Page404() {
  <Head>
    <title>{title}</title>
  </Head>;
  return (
    <>
      <Result
        status="404"
        title={title}
        extra={
          <Link href="/">
            <Button type="primary">На главную</Button>
          </Link>
        }
      />
    </>
  );
}
